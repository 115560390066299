.profile-block {
    padding: 30px;
}
.cardImageStatusUser {
    position: absolute;
    z-index: 999999;
    bottom: 0px;
    right: 50px;
}

.profile-left-section {
    display: inline-block;
    margin-top: 60px;
}

.profile_image_container {
    height: 200px;
    width: 200px;
    border-radius: 100%;
    overflow: hidden;
    display: block;
    position: relative;
}

.profile_image_container img {
    width: 100%;
    height: auto;
}

.username {
    font-size: 20px;
}

.location {
    font-size: 14px;
    color: gray;
    font-weight: normal;
}

.btn-block {
    background-color: orange!important;
    border-radius: 40px!important;
    color: white!important;
}

.btn-report {
    background-color: rgb(159, 3, 3)!important;
}

#gallery {
    padding: 10px 0 0 10px;
    background-color: white;
    text-align: center;
    margin: 0 auto;
    border: 2px solid blue;
  }
  .gallery-item-container {
    margin-bottom: 5px!important;
    float: left;
    margin-right: 5px;
  }
  
  .gallery-item {
    width: 200px;
    height: 200px;
    overflow: hidden;
    cursor: pointer;
    border: 10px solid #fff;
    border-radius: 5px;
    /* box-shadow: 0px 0px 4px 1px rgba(0, 0, 0, 0.2); */
  }

  .detailsHeaderStyleOnline {
    float: right;
  }

  .profileContentStyleSpace {
    font-weight: bold;
    font-size: 18px;
  }
  .info-block {
    clear: both;
    display: block;
    width: 100%;
  }

  .tab-holder {
    display: block;
    clear: both;
  }

  .border-item {
    display: block;
    background-color: rgb(237, 237, 237);
    height: 1px;
    width: 100%;
    margin-top: 20px;
    margin-bottom: 0px;
  }