#complete_profile_modal {
    position: fixed;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    background-color: #FFFFFF;
    z-index: 9999999999;
}

#page_loader {
    position: fixed;
    display: block;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    background-color: #FFF;
    z-index: 99999999;
}