.info-icon-alignment{
    margin-left: 10px !important;
}

.custom-border{
    border: 1px solid red;
}

.asterisk {
  color:  #f1416c;
}